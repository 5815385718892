import React, {
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { Row, Col } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Range } from "slate";
import * as Y from "yjs";
import { WebsocketProvider } from "@surge-global-engineering/y-websocket";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { cloneDeep, debounce, throttle } from "lodash";
import { v4 } from "uuid";
import {
  HeadingToolbar,
  Plate,
  focusEditor,
  PlateProvider,
  withPlate,
  TNodeEntry,
  TNode,
  TRenderLeafProps,
  createPlateEditor,
  Value,
  getOperations,
  withoutNormalizing,
} from "@udecode/plate";
import { withYjs, YjsEditor } from "@slate-yjs/core";
import { IndexeddbPersistence } from "@surge-global-engineering/y-indexeddb";

import useRootStore from "../../store/useRootStore";
import { SceneUtils } from "../../utils/scene/sceneServices";
import Can from "../casl/Can";
import { can } from "../casl/helpers";
import { EditorRightMenu } from "../Editor/RightMenu/EditorRightMenu";
import { withScenes } from "./plugins/ornamental-break/normalizers/withScenes";
import {
  withTrackChanges,
  toggleEditorMode,
} from "./plugins/track-changes/withTrackChanges";
import { getTrackChangeNodeEntries } from "./plugins/track-changes/queries/getTrackChangeNodeEntries";
import {
  TTrackChange,
  TrackChangeReply,
  TrackChangesProperties,
} from "./plugins/track-changes/types";
import { MyRootBlock, PlateNodes } from "./config/typescript";
import { CONFIG } from "./config/config";
import {
  CommentsPopover,
  TComment,
  unsetCommentNodesById,
  getComments,
} from "./plugins/comments";
import {
  Toolbar,
  WithEditorStyle,
  Titlebar,
  FloatingToolBar,
  BottomBar,
  MasterPageBottomBar,
} from "./partials";
import { plugins } from "./configure";
import {
  resolveComment,
  submitReplyComment,
  deleteComment,
  updateComment,
  approveTrackChange,
  declineTrackChange,
  submitReplyTrackChange,
  updateTrackChangeReply,
  deleteTrackChangeReply,
  getWordsCount,
  getAllSearchRanges,
  getNextSearchMatchStep,
  getSearchRanges,
  replaceAll,
  replaceOne,
  getLeafStyles,
  updateAllChaptersJSONCache,
  updateChapterJSONCache,
  IntervalController
} from "./helpers";
import { getAllCommentsAndTC } from "../CollaborationRHSMenu/helper";
import { ContentAlteringOpTypes } from "../../utils/helper";

const SYNC_URL = process.env.REACT_APP_Y_WS_SYNC_URL || "";

interface EditorProps {
  initialChapterBody?: MyRootBlock[];
}

const _Editor: React.FC<EditorProps> = ({ initialChapterBody }) => {
  /** store methods / variables */
  const { refreshCacheEditorOnChange, refreshCache } =
    useRootStore().pdfCacheStore;
  const { getExpectOrnamentalBreakDeletion, setExpectOrnamentalBreakDeletion } =
    useRootStore().sideMenuStore;
  const {
    chapterTemplateView,
    editor_setting,
    sidebarPreviewerOnlyMode,
    appEventEmitter,
  } = useRootStore().appStore;
  const {
    debouncedSaveChapterTemplateBodyUpdates,
    setWordsCount,
    chapterTemplate,
    searchParams,
    replaceParams,
    searchRange,
    searchMatchedRanges,
    setSearchMatchedRanges,
    searchStep,
    setSearchStep,
    wordCountType,
    book,
    getEditorCurrentOperation,
    doSearchSetCount,
    saveChapterMetaUpdates
  } = useRootStore().bookStore;
  const {
    chapterTemplates,
    chapterMeta: selectedChapter,
    currentScene,
    getCurrentScene,
    setChapterBody,
  } = useRootStore().chapterStore;
  const {
    activeCommentId,
    setActiveCommentId,
    comments,
    setCommentsAndTrackChanges,
    getCommentById,
  } = useRootStore().commentsStore;
  const { setChapterScenes } = useRootStore().sideMenuStore;
  const { user, token } = useRootStore().authStore;
  const {
    setTrackChanges,
    hasDifferentTrackChanges,
    activeTrackChangeId,
    setActiveTrackChangeId,
    setTrackChangeMode,
    trackChanges: storeTrackChanges,
  } = useRootStore().trackChangesStore;

  /** find and replace */
  const selectRef = useRef<HTMLSpanElement>(null);
  const searchTerm = useRef(searchParams);
  const editorSearchRange = useRef(searchRange);
  const [searchCounter, setSearchCounter] = useState(0);

  /** should the input field in editor title bar be in focus */
  const shouldTitleBarFocus = useRef(false);
  /** editor ref for programmatic scrolling */
  const plateEditorRef = useRef<HTMLDivElement>(null);

  /** maintaing loading status until editor ready to mount */
  const [isLoading, setIsLoading] = useState(true);

  /** flag to set true once the y updates are synced to ydoc from IDB */
  const [isIdbSynced, setIsIdbSynced] = useState(false);

  /** interval to check if the yjs editor is connected and update the loading status */
  const editorConnectionInterval = useRef<IntervalController | null>(null);

  /**
   * Editor titlebar:
   * auto focus title bar input field on chapter / masterpage change if necessary
   * on chapter / master page change, and scroll editor to the top
   * */
  useEffect(() => {
    const isChapterTitleEmpty = chapterTemplateView
      ? !!chapterTemplate.title
      : !!selectedChapter.title;
    shouldTitleBarFocus.current = isChapterTitleEmpty;
    /** scroll editor to the top on chapter / master page chane */
    if (plateEditorRef.current) {
      plateEditorRef.current.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [chapterTemplate.title, chapterTemplateView, selectedChapter.title]);

  /**
   * Master pages:
   * check if the current chapter is an instance of a master page by checking if
   * 1. A master page is created using the current chapter (template.motherChapterId === selectedChapter._id)
   * 2. The current chapter is created using a master page (template._id === selectedChapter.templateId)
   * */
  const isMasterPage = useMemo(() => {
    return chapterTemplates.some(
      (template) =>
        template.motherChapterId === selectedChapter._id ||
        template._id === selectedChapter.templateId
    );
  }, [selectedChapter._id, chapterTemplates, selectedChapter.templateId]);

  /**
   * Master pages:
   * Set the initial chapter body to the master page body if the current chapter is a master page
   */
  const initiChapterBody = useMemo(() => {
    if (chapterTemplateView) return initialChapterBody;
    return undefined;
  }, [chapterTemplateView, initialChapterBody]);

  /**
   * YJS:
   * initialize yjs shared type and providers
   * */
  const [sharedType, wsProvider, dbProvider] = useMemo(() => {
    const clientInstnaceId = v4();
    const ydoc = new Y.Doc();
    const sharedType = ydoc.get("content", Y.XmlText) as Y.XmlText;
    const dbProvider = new IndexeddbPersistence(selectedChapter._id, ydoc);
    const wsProvider = new WebsocketProvider(
      SYNC_URL,
      selectedChapter._id,
      ydoc,
      {
        connect: false,
        params: {
          clientId: clientInstnaceId,
          token: token as string,
        },
      }
    );
    return [sharedType, wsProvider, dbProvider];
  }, [selectedChapter._id, token]);


  useEffect(() => {
    if(dbProvider){
      dbProvider.on("synced", () => setIsIdbSynced(true));
    }
    return () => {
      setIsIdbSynced(false);
      dbProvider?.off("synced", () => setIsIdbSynced(true));
    };
  }, [dbProvider]);

  /**
   * Plate | YJS:
   * initialize editor
   * */
  const editor: any = useMemo(() => {
    /** editor instance without yjs for masterpages */
    if (chapterTemplateView) {
      return withPlate(createPlateEditor(), {
        plugins,
        disableCorePlugins: { history: true },
      });
    } else if (sharedType && plugins) {
      /** collaborative editor with yjs */
      return withPlate(
        withYjs(withTrackChanges(createPlateEditor()), sharedType),
        {
          plugins,
          disableCorePlugins: { history: true },
        }
      );
    }
  }, [chapterTemplateView, sharedType]);

  /**
   * YJS:
   * Connect yjs editor upon initialization and disconnect on unmount
   * */
  useEffect(() => {
    /** handle editor mount */
    if (editor && !chapterTemplateView && !YjsEditor.connected(editor) && isIdbSynced) {
      YjsEditor.connect(editor);
      editorConnectionInterval.current = new IntervalController(() => {
        return YjsEditor.connected(editor);
      }, () => setIsLoading(false));
    }
    const disconnectEditor = () => {
      if (editor && !chapterTemplateView && YjsEditor.connected(editor)) {
        YjsEditor.disconnect(editor);
      }
    };
    return () => {
      disconnectEditor();
      editorConnectionInterval.current?.clearSetInterval();
    };
  }, [editor, chapterTemplateView, isIdbSynced]);

  /**
   * YJS:
   * Connect y websocket provider upon initialization and disconnect on unmount
   * */
  useEffect(() => {
    if (wsProvider) {
      wsProvider.connect();
    }
    return () => wsProvider.disconnect();
  }, [wsProvider]);

  /**
   * Scenes:
   * callback to update scene indexes from the withScenes plugin
   */
  const updateChapterSceneState = useCallback(
    (props?: { refreshPDFCache?: boolean }) => {
      const scenes = SceneUtils.getChapterScenes(editor);
      setChapterScenes(selectedChapter._id, scenes);
      if (props?.refreshPDFCache) {
        refreshCache(book._id, "chapter-contents-change", {
          "chapter-contents-change": { chapterId: selectedChapter._id },
        });
      }
    },
    [editor, selectedChapter._id, setChapterScenes, book._id, refreshCache]
  );

  /**
   * Scenes:
   * withScenes plugin to handle scene related functionality
   */
  const withScenesEditor = useMemo(() => {
    return withScenes(editor, getCurrentScene, updateChapterSceneState);
  }, [editor, currentScene, updateChapterSceneState]);

  /**
   * Find and replace:
   * editor decorations for find and replace
   * */
  const decorate = useCallback(
    ([node, path]: TNodeEntry<TNode>) =>
      getSearchRanges(
        node,
        path,
        searchTerm.current,
        toJS(editorSearchRange.current)
      ) as unknown as Range[],
    [searchTerm.current, selectedChapter, editorSearchRange.current]
  );

  /**
   * Updates the search state for based on the provided search parameters.
   * This function calculates all matching ranges, sets the match step, and updates
   * references for the current search term and active search range.
   * */
  const updateSearch = () => {
    const sceneNode = currentScene?.scene.sceneNodeEntry;
    const ranges = getAllSearchRanges(editor, searchParams, sceneNode);
    setSearchMatchedRanges(ranges);

    const step = getNextSearchMatchStep(editor, ranges);
    setSearchStep(step);

    searchTerm.current = searchParams;
    editorSearchRange.current = toJS(searchMatchedRanges)[searchStep];
  };

  /**
   * Track changes:
   * toggle editor mode to track changes mode
   */
  useEffect(() => {
    if (can("view", "track-changes")) {
      setTrackChangeMode(true);
      toggleEditorMode(withScenesEditor, "TrackChanges");
  }
      
  }, [withScenesEditor]);

  const handleUndoRedoKeyDown = (event) => {
    // Detect Undo (Ctrl+Z or Cmd+Z)
    if ((event.ctrlKey || event.metaKey) && event.key === "z") {
      editor.history.undos = [];
    }
    // Detect Redo (Ctrl+Y or Cmd+Shift+Z)
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "y" || (event.shiftKey && event.key === "z"))
    ) {
      editor.history.undos = [];
    }
  };

  /**
   * Find and replace | Track changes:
   * customized leaf node rendering
   * */
  const renderLeafComponent = useCallback(
    ({ attributes, children, leaf }: TRenderLeafProps) => {
      const styles = getLeafStyles(leaf, activeTrackChangeId);
      const isTrackChangeLeaf = leaf.trackChanges ? true : false;
      const contentEditable = isTrackChangeLeaf
        ? (leaf.trackChanges as TrackChangesProperties).userId === user?._id
        : true;

      if (leaf.select_highlight) {
        selectRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }

      return isTrackChangeLeaf && can("view", "track-change-content") ? (
        <span
          {...attributes}
          {...(isTrackChangeLeaf ? { contentEditable } : {})}
          suppressContentEditableWarning={true}
          onClick={() => {
            setActiveTrackChangeId(
              (leaf.trackChanges as TrackChangesProperties).tcId
            );

            if (activeCommentId) {
              setActiveCommentId(null);
            }
          }}
          {...(leaf.isFocusedSearchHighlight
            ? {
                "data-testid": "search-focused",
              }
            : {})}
          style={styles}
        >
          {children}
        </span>
      ) : !isTrackChangeLeaf ? (
        <span
          ref={leaf.select_highlight ? selectRef : null}
          {...attributes}
          onClick={() => {
            if (activeCommentId) {
              setActiveCommentId(null);
            }
          }}
          {...(leaf.isFocusedSearchHighlight
            ? {
                "data-testid": "search-focused",
              }
            : {})}
          style={styles}
        >
          {children}
        </span>
      ) : (
        <></>
      );
    },
    [
      activeCommentId,
      activeTrackChangeId,
      setActiveCommentId,
      setActiveTrackChangeId,
      user?._id,
    ]
  );

  /**
   * Find and replace:
   * update search terms search ranges for find and replace
   * */
  useEffect(() => {
    updateSearch();
  }, [searchParams, editor, isLoading, selectedChapter]);

  /**
   * Find and replace:
   * update search range and search counter
   */
  useEffect(() => {
    editorSearchRange.current = searchMatchedRanges[searchStep];
    setSearchCounter(searchCounter + 1);
  }, [searchMatchedRanges, searchStep]);

  useEffect(() => {
    // Search params empty and matched ranges empty cannot be replaced
    if(searchParams.q && searchMatchedRanges.length > 0 && !isLoading){
      handleReplace(replaceParams);
      
      //reset/update search after replace
      updateSearch();
    }
  }, [replaceParams]);

  /**
   * Word count:
   * update word count when word count type changes
   */
  useEffect(() => {
    countSelectedWords();
  }, [wordCountType]);

  /**
   * App events:
   * Event listeners and Event handlers for app events (sidebar operations)
   */
  useEffect(() => {
    const handleDeleteScene = (data: IAppEvents.DeleteSceneMeta) => {
      const isCurrentChapterEvent = selectedChapter._id === data.chapterId;
      if (isCurrentChapterEvent) {
        SceneUtils.removeEditorScene(
          withScenesEditor,
          data.sceneId,
          data.sceneIndex,
          data.deleteContent,
          () => {
            updateChapterSceneState({ refreshPDFCache: true });
          }
        );
      }
    };
    const handleMoveScene = (data: IAppEvents.MoveSceneMeta) => {
      const isCurrentChapterEvent = selectedChapter._id === data.chapterId;
      if (isCurrentChapterEvent) {
        SceneUtils.moveScenesWithinChapter(
          withScenesEditor,
          data.srcSceneIndex,
          data.destSceneIndex,
          () => {
            updateChapterSceneState({ refreshPDFCache: true });
          }
        );
      }
    };
    const handleDndDragScene = (data: IAppEvents.DndDragSceneMeta) => {
      SceneUtils.handleDnDDragChapterOperations(
        withScenesEditor,
        data.srcSceneEntry,
        data.srcObEntry,
        () => {
          updateChapterSceneState({ refreshPDFCache: true });
        }
      );
    };
    const handleDndDropScene = (data: IAppEvents.DndDropSceneMeta) => {
      withoutNormalizing(withScenesEditor, ()=>  {
        SceneUtils.handleDnDDropChapterOperations(
          withScenesEditor,
          data.nodesToInsert,
          data.dropPath,
          () => {
            updateChapterSceneState({ refreshPDFCache: true });
          }
        );
      });
    };
    if (appEventEmitter) {
      appEventEmitter.on("delete_scene", handleDeleteScene);
      appEventEmitter.on("move_scene", handleMoveScene);
      appEventEmitter.on("dnd_drag_scene", handleDndDragScene);
      appEventEmitter.on("dnd_drop_scene", handleDndDropScene);
    }
    return () => {
      if (appEventEmitter) {
        appEventEmitter.off("delete_scene", handleDeleteScene);
        appEventEmitter.off("move_scene", handleMoveScene);
        appEventEmitter.off("dnd_drag_scene", handleDndDragScene);
        appEventEmitter.off("dnd_drop_scene", handleDndDropScene);
      }
    };
  }, [
    appEventEmitter,
    withScenesEditor,
    updateChapterSceneState,
    selectedChapter._id,
    refreshCache,
    book._id,
  ]);

  useEffect(() => {
    updateAllChaptersJSONCache();
  }, [book._id, book.chapterIds.length]);

  /**
   * Comments:
   * remove the comment mark if user leaves the chapter before submitting a comment
   */
  useLayoutEffect(() => {
    const removeUnsubmittedComments = (editor, id: string) => {
      unsetCommentNodesById(editor, { id });
    };
    return () => {
      const comments = getComments(editor);
      const commentExists = comments.some(
        (comment) => comment.id === activeCommentId
      );
      if (activeCommentId && !commentExists) {
        removeUnsubmittedComments(editor, activeCommentId);
      }
    };
  }, [editor, activeCommentId]);

  useEffect(() => {
    const commentsAndTC = getAllCommentsAndTC(editor, getCommentById);
    setCommentsAndTrackChanges(commentsAndTC);
  }, [storeTrackChanges, comments]);

  /**
   * Find and replace:
   * utility funtions
   */
  const handleReplace = (params: IBookStore.ReplaceParams) => {
    if (params.all) {
      replaceAll(editor, params.text, searchMatchedRanges);
    } else {
      replaceOne(editor, params.text, searchRange);
    }
  };

  /**
   * Word count:
   * utility functions
   */

  /** if editor focus change, re-calculate word count if word count setting was editor selection */
  const handleEditorOnBlur = () => {
    if (wordCountType === "selection") {
      setWordsCount(0);
    }
  };
  /** count words within the editor selection */
  const countSelectedWords = () => {
    if (wordCountType !== "selection") return;
    const selectedContent = editor.getFragment();
    if (
      selectedContent &&
      selectedContent.length > 0 &&
      selectedContent[0].children
    ) {
      setWordsCount(getWordsCount(selectedContent));
    }
  };

  /**
   * Comments:
   * utility funtions
   */

  const editorResolveComment = (commentId: string) => {
    resolveComment(editor, commentId, setActiveCommentId);
  };

  const editorSubmitReplyComment = (replyValue: string) => {
    submitReplyComment(editor, replyValue, activeCommentId, user?._id);
  };

  const editorDeleteComment = (deletingComment: TComment) => {
    deleteComment(editor, deletingComment, setActiveCommentId);
  };

  const editorUpdateComment = (updatingComment: TComment) => {
    updateComment(editor, updatingComment);
  };

  /**
   * Track changes:
   * utility functions
   */

  const editorApproveTrackChange = (trackChanges: TTrackChange[]) => {
    approveTrackChange(withScenesEditor, trackChanges);
  };

  const editorDeclineTrackChange = (trackChanges: TTrackChange[]) => {
    declineTrackChange(withScenesEditor, trackChanges);
  };

  const editorSubmitReplyTrackChange = (
    replyValue: Value,
    trackChange: TTrackChange
  ) => {
    submitReplyTrackChange(withScenesEditor, user, replyValue, trackChange);
  };

  const editorUpdateTrackChangeReply = (
    trackChangeNode: TTrackChange,
    updatedValue: string,
    reply: TrackChangeReply
  ) => {
    updateTrackChangeReply(
      withScenesEditor,
      trackChangeNode,
      updatedValue,
      reply
    );
  };

  const editorDeleteTrackChangeReply = (
    trackChange: TTrackChange,
    reply: TrackChangeReply
  ) => {
    deleteTrackChangeReply(withScenesEditor, trackChange, reply);
  };

  /**
   * Editor:
   * utility functions
   */

  const debouncedSetChapterBody = useCallback(debounce(setChapterBody, 500), [
    setChapterBody,
  ]);

  /**
   * Editor | Book:
   * Update books and chapter lastUpdateAt timestamp on editor change
   */

  const updateChapterLastUpdateAt = async() => {
    await saveChapterMetaUpdates(selectedChapter, true, true, true);
  };

  const throttledUpdateChapterLastUpdateAt = useCallback(
    throttle(updateChapterLastUpdateAt, 60000),
    [selectedChapter]
  );

  const handleEditorOnChange = (nodes: PlateNodes) => {
    /**
     * Handle track changes:
     * Extract track changes from the editor and update the track changes store if necessary
     * */
    const trackChangeNodeEntries = getTrackChangeNodeEntries(editor);
    const trackChanges: TTrackChange[] = [];
    for (const [childNode, childPath] of trackChangeNodeEntries) {
      trackChanges.push({ path: childPath, tc: childNode });
    }
    const groupedTCsById = new Map<string, TTrackChange[]>();
    for (const trackChange of trackChanges) {
      const trackChangeId = trackChange.tc.trackChanges.tcId;
      if (!groupedTCsById.has(trackChangeId)) {
        groupedTCsById.set(trackChangeId, []);
      }
      (groupedTCsById.get(trackChangeId) || []).push(trackChange);
    }
    const trackChangeArray = Array.from(groupedTCsById.values());
    const hasTrackChangeUpdates = hasDifferentTrackChanges(trackChangeArray);
    if (hasTrackChangeUpdates) {
      setTrackChanges(trackChangeArray);
    }
    /**
     * Update word count
     */
    countSelectedWords();

    const event = cloneDeep(nodes);
    /**
     * Check if editor onChange is triggered by an scene break removal
     * The state variable for scene break removal is updated from within the plugin component
     */
    const sceneBreakRemoved = getExpectOrnamentalBreakDeletion();
    if (sceneBreakRemoved) {
      setExpectOrnamentalBreakDeletion(false);
    }
    /**
     * Get the current operation and check if the highlighted words for 'find' in
     * find & replace need to re-calculated based on the operation type
     */
    const debouncedSearchCountUpdate = debounce(async() => {
      const currentOperation = getEditorCurrentOperation();
      if (
        currentOperation &&
        ContentAlteringOpTypes.includes(currentOperation?.type)
      ) {
        const ranges = getAllSearchRanges(editor, searchTerm.current);
        setSearchMatchedRanges(ranges);
        await updateChapterJSONCache(selectedChapter._id);
        doSearchSetCount();
      }
    }, 500);

    if(editor_setting.show && editor_setting.view === "search"){
      debouncedSearchCountUpdate();
    }
    //check if it's chapter template
    if (chapterTemplateView && chapterTemplate._id) {
      //save chapter template
      debouncedSaveChapterTemplateBodyUpdates(chapterTemplate._id, event);
    } else {
      refreshCacheEditorOnChange(selectedChapter.bookId, selectedChapter._id);
      debouncedSetChapterBody(nodes);
      const debouncedJSONCacheUpdate = debounce(updateChapterJSONCache, 3000);
      debouncedJSONCacheUpdate(selectedChapter._id);
      // Update chapter and book lastUpdateAt timestamp every 60 seconds
      throttledUpdateChapterLastUpdateAt();
    }
  };

  const preventDragAndDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <Row wrap={false} className="editor-wrapper">
        <Col flex={1} className="atticus-editor-container">
          <DndProvider backend={HTML5Backend}>
            <PlateProvider<PlateNodes>
              editor={withScenesEditor}
              plugins={plugins}
              onChange={handleEditorOnChange}
              {...(chapterTemplateView && {
                initialValue: initiChapterBody,
              })}
              normalizeInitialValue={true}
            >
              <Can action={"view"} subject={"entire-toolbar"}>
                <Row className="editor-header">
                  <Col span={24}>
                    <HeadingToolbar className="plate-toolbar-overrides">
                      <Toolbar />
                    </HeadingToolbar>
                  </Col>
                  <Col className="editor-settings-toolbar-container"></Col>
                </Row>
              </Can>

              <WithEditorStyle />
              <div className="editor-col">
                <div className="editor-area scroller" ref={plateEditorRef}>
                  <Titlebar
                    onEnter={() => {
                      focusEditor(editor);
                    }}
                    // placeholder={`${
                    //   sceneIndex !== null ? "Scene" : "Chapter"
                    // } Title`}
                    placeholder={`${"Chapter"} Title`}
                    autofocus={shouldTitleBarFocus.current}
                    editor={withScenesEditor}
                  />
                  {isLoading && !chapterTemplateView ? (
                    <div className="editor-spinner-area">
                      <div className="sync-container">
                        <img src="/images/syncing.gif" alt="Syncing" />
                        <div className="sync-text">Syncing....</div>
                      </div>
                      <Plate
                        editableProps={{
                          className: "editor-loading-textarea",
                        }}
                      >
                        {editor_setting.view === "collaboration" && editor_setting.show && (
                          <>
                            <FloatingToolBar />
                            <CommentsPopover />
                          </>
                        )}
                      </Plate>
                    </div>
                  ) : (
                    <Plate
                      editableProps={{
                        ...CONFIG.editableProps,
                        decorate: decorate,
                        renderLeaf: renderLeafComponent,
                        onBlur: handleEditorOnBlur,
                        onKeyDown: (e) => {
                          can("view", "track-changes")
                            ? handleUndoRedoKeyDown(e)
                            : null;
                        },
                        readOnly: !can("view", "direct-edits"),
                        ...(!can("view", "content-draggable") && {
                          onDrag: preventDragAndDrop,
                          onDrop: preventDragAndDrop,
                        }),
                      }}
                    >
                      {editor_setting.view === "collaboration" && editor_setting.show && (
                          <>
                            <FloatingToolBar />
                            <CommentsPopover />
                          </>
                        )}
                    </Plate>
                  )}
                </div>
              </div>
            </PlateProvider>
          </DndProvider>
          {(isMasterPage || chapterTemplateView) &&
          can("view", "masterpages-apply-changes") ? (
            <MasterPageBottomBar />
          ) : null}
          <BottomBar />
        </Col>
        {editor_setting.show &&
        !sidebarPreviewerOnlyMode &&
        !chapterTemplateView ? (
          <Col
            id="sidebar"
            flex={
              editor_setting.view === "previewer"
                ? ""
                : editor_setting.view === "collaboration"
                ? "330px"
                : "320px"
            }
            className="setting-area-wrapper"
          >
            <EditorRightMenu
              view={editor_setting.view}
              resolveComment={editorResolveComment}
              submitReplyComment={editorSubmitReplyComment}
              deleteComment={editorDeleteComment}
              updateComment={editorUpdateComment}
              approveTrackChange={editorApproveTrackChange}
              declineTrackChange={editorDeclineTrackChange}
              submitReplyTrackChange={editorSubmitReplyTrackChange}
              updateTrackChangeReply={editorUpdateTrackChangeReply}
              deleteReply={editorDeleteTrackChangeReply}
              editor={withScenesEditor}
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export const Editor = observer(_Editor);
